import request from '../../request/request'
export function login(data) {
    return request({
        url:'/UserLogin',
        method:'post',
        data
    })
}
// 忘记密码
export function revise(data){
    return request({
        url:'/ForgetPassword',
        method:'post',
        data
    })
}